<template>
  <a-form :colon="false">
    <div class="control">
      <a-button-group size="small">
        <Notice
          :disabled="disabled"
          :noticeList="noticeList"
          :selectedKey="selectedKey"
          @setNoticeList="setNoticeList"
        />

        <a-dropdown :disabled="disabled">
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item key="1" class="menu-item">
              <img class="icon" :src="approval5Image" alt />
              驳回上一步
            </a-menu-item>
            <a-menu-item key="2" class="menu-item">
              <img class="icon" :src="approval2Image" alt />
              驳回到发起人
            </a-menu-item>
            <a-menu-item key="3" class="menu-item">
              <img class="icon" :src="approval3Image" alt />
              终止
            </a-menu-item>
            <a-menu-item key="4" class="menu-item">
              <img class="icon" :src="approval2Image" alt />
              <Sign :visible="visible" @show="isShow">加签</Sign>
            </a-menu-item>
          </a-menu>
          <a-button class="button">
            <img class="icon" :src="approval7Image" alt />
            更多
          </a-button>
        </a-dropdown>
      </a-button-group>
    </div>

    <a-form-item label="意见">
      <a-textarea
        :disabled="disabled"
        :value="detail.comment"
        @change="handleCommentChange"
        :auto-size="{ minRows: 3 }"
      />
    </a-form-item>
    <a-form-item label="打分" v-if="scoreVisible">
      <div class="left">
        <a-slider
          :disabled="disabled"
          :value="score"
          :max="100"
          @change="handleSliderChange"
          style="flex: 1"
        />
        <a-button-group size="small" style="margin-left: 8px">
          <a-button icon="minus" @click="reduceScore" :disabled="disabled" />
          <a-input
            :disabled="disabled"
            style="width: 40px"
            size="small"
            :value="score"
            @change="handleScoreChange"
          />
          <a-button icon="plus" @click="addScore" :disabled="disabled" />
        </a-button-group>
      </div>
    </a-form-item>

    <a-form-item label="项目核算模式" v-if="formulaVisible">
      <a-select :disabled="disabled" :value="formula" @change="handleFormulaChange">
        <a-select-option
          v-for="item in formulaList"
          :key="item.value"
          :value="item.value"
          :disabled="item.value === '2020'"
        >{{ item.name }}</a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      label="经营费用总额度"
      v-if="formulaVisible && (formula === '2022' || formula === '2023' || formula === '2024' || formula === '2025')"
    >
      <a-input-number
        :disabled="true"
        :min="0"
        :max="1"
        :value="formula === '2023' || formula === '2024' || formula === '2025'? 0.06 : factor"
        @change="handleFactorChange"
      />
    </a-form-item>

    <a-form-item label="是否需要财务审核" v-if="needFinancialApproval">
      <a-radio-group
        :disabled="disabled"
        :value="isFinancialApproval"
        @change="handleFinancialApprovalChange"
      >
        <a-radio
          v-for="item in [{
            name: '是',
            value: 1,
          }, {
            name: '否',
            value: 0,
          }]"
          :key="item.value"
          :value="item.value"
        >{{ item.name }}</a-radio>
      </a-radio-group>
    </a-form-item>

    <div class="center">
      <a-space>
        <a-button v-if="assignees && assignees.length" @click="tempSave">暂存</a-button>

        <a-button
          type="primary"
          @click="save"
          :disabled="disabled"
          :loading="loading"
        >{{ detail.status === "RESUBMITED" ? "重新提交" : "通过" }}</a-button>
        <a-button @click="reapply" v-if="detail.status === 'RESUBMITED'">修改后提交</a-button>

        <div v-if="assignees && assignees.length">
          加签人员
          <span style="color: #1890ff">({{ assignees.length }})</span>
        </div>
      </a-space>
    </div>
  </a-form>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Notice from "../Notice";
import Sign from "./components/Sign";

import approval7Image from "@/assets/approval7.png";
import approval2Image from "@/assets/approval2.png";
import approval3Image from "@/assets/approval3.png";
import approval5Image from "@/assets/approval5.png";

export default {
  components: {
    Notice,
    Sign
  },

  data() {
    return {
      noticeList: [], // 需要知会的人员列表
      selectedKey: "approvalDetail",
      loading: false,

      approval7Image, // icon
      approval2Image, // icon
      approval3Image, // icon
      approval5Image, // icon

      visible: false, //加签对话框的显示

      status: "" //用于判断从epc文件列表调过来时，是否可审核，若为COMPLETED为可审核，否则不可审核
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("approval", [
      "detail",
      "oldDelayedDate",
      "assignees",
      "signType",
      "history"
    ]),
    ...mapState("approvalDetail", ["approvalList"]),
    ...mapGetters("approval", {
      isdisabled: "disabled"
    }),
    ...mapGetters("approval", [
      "scoreVisible",
      "score",
      "formulaVisible", // 产值分配计算公式
      "formula",
      "factor",
      "isSupervise",
      "isSuperviseWorker",

      "needFinancialApproval", // 是否需要财务审批
      "isFinancialApproval",

      "executeRemarks",
      "executeAttachments",
      "isDelayed",
      "delayedDate",
      "delayRemarks",

      "isInvoice",
      "isFinanceDrawer",

      "isManageObject",
      "isVisa",
      "isChangeApproval",
      "isSafeInspection",
      "isOrganizeDesign",
      "isQualityInspection",
      "isDataInspection",
      "isClaim",
      "isCounterClaim",
      "isBiddingResult",

      "applyType",
      "invoiceList",
      "isPostMan",
      // "isConfirmer",
      "sendAttachment",
      // "isSent",

      "salesUnitName", // 销方名称
      "drawDate", // 开票日期

      "isPutOnFileRequest",
      "isBidRequestEPCLeader", // 项目投标申请(EPC-牵头人)
      "isBidRequestEPCLeaderNot",
      "isBidRequestDesign",

      "ofc_quality_rectify" // 安全生产-整改工作单
    ]),

    isSCRM() {
      return (
        this.isPutOnFileRequest ||
        this.isBidRequestEPCLeaderNot ||
        this.isBidRequestEPCLeader ||
        this.isBidRequestDesign
      );
    },

    ...mapGetters("setting", ["findDataDict"]),

    // 提示语集合
    messageList() {
      return this.findDataDict("message");
    },

    formulaList() {
      return this.findDataDict("pallocateCalcFormula");
    },

    disabled() {
      return this.isdisabled && this.status !== "RUNNING";
    }
  },

  mounted() {
    const { status } = this.$route.query;
    this.status = status;
  },
  methods: {
    ...mapMutations("approval", [
      "setDetail",
      "setScore",
      "setFormula",
      "setFactor",

      "addDrawer",
      "addDrawDate",

      "setSignUser",
      "setSignType",
      "setOnSet",

      "setIsFinancialApproval" // 设置财务审批
    ]),
    ...mapActions("approval", [
      "finishTask",
      "signTaskStorage",
      "signTaskSubmit"
    ]),

    // 改变分数
    handleScoreChange(e) {
      const value = Number(e.target.value);

      if (!value) {
        this.setScore(0);
      } else if (value > 100) {
        this.setScore(100);
      } else {
        this.setScore(value);
      }
    },
    handleSliderChange(value) {
      this.setScore(value);
    },
    handleFormulaChange(value) {
      this.setFormula(value);
    },

    handleFinancialApprovalChange(e) {
      console.log(e.target.value);
      this.setIsFinancialApproval(e.target.value);
    },

    handleFactorChange(value) {
      this.setFactor(value);
    },
    reduceScore() {
      if (this.score === 0) {
        return;
      }
      this.setScore(this.score - 1);
    },
    addScore() {
      if (this.score === 100) {
        return;
      }
      this.setScore(this.score + 1);
    },

    // 用户点击取消审批
    handleMenuClick(e) {
      if (e.key === "1") {
        //  驳回至上一步
        // 若上一步的人为空， 弹窗提示只能驳回到发起人
        const index = this.history.findLastIndex(
          ite => ite.assignee === this.detail.assignee
        );

        if (this.history[index - 1].assignee === "none") {
          return this.$message.error("上一步审核人员未指定，只能驳回至发起人");
        }
        this.control({
          operation: "REJECTED_LAST",
          comment: this.detail.comment
        });
      } else if (e.key === "2") {
        // 驳回到发起人
        this.control({
          operation: "REJECTED_FIRST",
          comment: this.detail.comment
        });
      } else if (e.key === "3") {
        const that = this;
        this.$confirm({
          title: this.messageList.find(item => item.value === "cancel-approval")
            .name,
          onOk() {
            that.control({
              operation: "CANCELLED",
              comment: that.detail.comment
            });
          }
        });
      } else if (e.key === "4") {
        // 加签
        this.visible = true;
      }
    },
    //加签框的取消
    isShow() {
      this.visible = false;
    },

    // 提交审批
    save() {
      let map = {
        operation: "COMPLETED",
        comment: this.detail.comment
      };

      if (this.scoreVisible) {
        map.score = this.score;
      }

      if (this.formulaVisible) {
        if (!this.formula) {
          this.$message.error("请选择项目产值计算公式！");
          return;
        }
        if (this.formula === "2022" && !this.factor) {
          this.$message.error("请输入经营费用总额度！");
          return;
        }

        map.formula = this.formula;
        map.factor = this.formula === "2025" ||this.formula === "2024" || this.formula === "2023" ? 0.06 : this.factor;
      }

      if (this.needFinancialApproval) {
        if (typeof this.isFinancialApproval !== "number") {
          this.$message.error("请选择是否需要财务审批");
          return;
        }
        map.isFinancialApproval = this.isFinancialApproval;
      }

      // 如果是督办任务
      if (this.isSupervise) {
        map.executeRemarks = this.executeRemarks;
        map.executeAttachments = this.executeAttachments.map(item => item.id);
        map.delayedDate = this.delayedDate;
        map.delayRemarks = this.delayRemarks;
        // 默认没值，执行人填了延期字段，需要给后端，
        // 发起人看到延期有值，点击保存还是要给后端，后端拿着这值去用，而这一步其实是可以省略的，方便后端不用取值而已，
        // 流程回到了执行人这里，延期是有旧值得，这时候如果没改，就不给后端，改了才给后端。
        // 这里只改 map，extra 属于只读数据，只要保证是最新的即可。

        // 执行人要延期，isDelayed 必须设置为1，如果不延期，设置为0
        if (this.isSuperviseWorker) {
          if (!this.oldDelayedDate && !this.delayedDate) {
            // 新旧延期日期都没值，不延期
            map.isDelayed = 0;
          } else if (
            this.delayedDate &&
            this.oldDelayedDate !== this.delayedDate
          ) {
            // 都有值
            // delayedDate 旧值跟新值不一样，才申请延期，否则不延期
            if (
              this.detail.extra &&
              this.detail.extra.superviseTaskData &&
              this.detail.extra.superviseTaskData.originalDate
            ) {
              if (
                moment(
                  this.detail.extra.superviseTaskData.originalDate
                ).isSameOrAfter(this.delayedDate)
              ) {
                // 如果原来的办结时限比选择的延期日期还要晚，说明选择的延期不对
                this.$message.error("延期日期不得小于办结时限！");
                return;
              }
            }

            map.isDelayed = 1;
          } else {
            map.isDelayed = 0;
          }
        }
      }

      // 开票退票换票审批
      if (this.isInvoice) {
        // 财务开票
        if (this.isFinanceDrawer) {
          // 校验用户是否填写了发票的相关字段
          if (!this.salesUnitName) {
            this.$message.error("请选择销方！");
            return;
          }
          // 开票日期
          if (!this.drawDate) {
            this.addDrawer(this.user.userName);
            this.addDrawDate(moment().format("YYYY-MM-DD HH:mm:ss"));
          }

          // 只要不是退票就要校验
          if (this.applyType !== "refund") {
            // 上传的发票数组为空
            if (this.invoiceList.length === 0) {
              this.$message.error("请上传发票！");
              return;
            }
          }
        }
        // 填写送达信息
        if (this.isPostMan) {
          if (!this.sendAttachment) {
            this.$message.error("请上传送达附件！");
            return;
          }
        }

        // // 确认送达信息
        // if (this.isConfirmer) {
        //   if (!this.isSent) {
        //     this.$message.error("请选择是否送达！");
        //     return;
        //   }
        // }
      }

      // 如果有加签
      if (this.assignees?.length) {
        let arr = [
          {
            type: "counter_sign",
            operation: "COUNTER_SIGN"
          },
          {
            type: "sequential_sign",
            operation: "SEQUENTIAL_SIGN"
          },
          {
            type: "parallel_sign",
            operation: "PARALLEL_SIGN"
          }
        ];
        map.operation =
          arr.find(item => item.type === this.signType).operation || "";
        this.signTaskSubmit(map);
        this.$emit("close");
        return;
      }

      if (this.ofc_quality_rectify) {
        if (!this.detail.extra.evidence) {
          this.$message.error("请填写整改依据");
          return;
        }
        if (Array.isArray(this.detail.extra.problemList)) {
          let flag = false;
          this.detail.extra.problemList.forEach(item => {
            if (!item.measure) {
              flag = true;
            }
          });
          if (flag) {
            this.$message.error("请填写整改措施");
            return;
          }
        }
      }
      console.log(map);

      this.control(map);
    },

    control(map) {
      if (this.addApprovalDetail()) {
        console.log("文件未上传，阻止提交");
        return;
      }

      this.loading = true;

      let params = {
        notifyPeople: this.noticeList.map(item => item.userId),
        map,
        extra: JSON.stringify(this.detail.extra)
      };
      if (this.isBiddingResult) {
        params.category = this.detail.category;
      }

      this.finishTask(params)
        .then(() => {
          this.$emit("close");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 添加审批详情
    addApprovalDetail() {
      // 校验表单是否必填
      // 1.若为设计变更审批，分包签证事项且为最后一步（设计变更审批到设计负责人，分包签证到造价工程师）,且选择上传经决会意见，
      if (
        ((this.isVisa &&
          this.detail.taskDefinitionKey ===
            "general_contract_proj_dept_cost_engineer") ||
          (this.isChangeApproval &&
            this.detail.taskDefinitionKey === "design_dept_design_manager")) &&
        this.detail.extra.meetingComments &&
        !this.approvalList.length
      ) {
        this.$message.error("请上传经决会意见！");
        return true;
      }

      // 2.若为管理目标责任书，且为最后一步时,且选择上传经决会意见，
      // manage_dept_business_specialist
      if (
        this.isManageObject &&
        this.detail.taskDefinitionKey ===
          "general_contract_division_cost_manager"
      ) {
        if (!this.approvalList.length) {
          this.$message.error("请上传经决会意见！");
          return true;
        } else {
          this.detail.extra.planningData.attachmentList = Array.isArray(
            this.detail.extra.planningData.attachmentList
          )
            ? this.detail.extra.planningData.attachmentList
            : [];
          this.detail.extra.planningData.attachmentList.push(
            ...this.approvalList
          );
        }
      }
      // 3.若为索赔，反索赔,选择了不符合审计原则且为最后一步时,且选择上传经决会意见
      if (
        (this.isClaim || this.isCounterClaim) &&
        this.detail.taskDefinitionKey ===
          "general_contract_proj_dept_prj_manager" &&
        this.detail.extra.constructionData.suitAudit &&
        !this.approvalList.length
      ) {
        this.$message.error("请上传经决会意见！");
        return true;
      }

      // 把附件添加到extra中
      if (this.isChangeApproval) {
        for (const item of this.approvalList) {
          this.detail.extra.designData.attachmentList = Array.isArray(
            this.detail.extra.designData.attachmentList
          )
            ? this.detail.extra.designData.attachmentList
            : [];
          this.detail.extra.designData.attachmentList.push(item);
        }
      }
      if (
        this.isSafeInspection ||
        this.isQualityInspection ||
        this.isDataInspection ||
        this.isClaim ||
        this.isCounterClaim
      ) {
        for (const item of this.approvalList) {
          this.detail.extra.constructionData.attachmentList = Array.isArray(
            this.detail.extra.constructionData.attachmentList
          )
            ? this.detail.extra.constructionData.attachmentList
            : [];
          this.detail.extra.constructionData.attachmentList.push(item);
        }
      }
    },

    // 加签暂存
    tempSave() {
      this.signTaskStorage();
    },

    handleCommentChange(e) {
      this.setDetail({
        ...this.detail,
        comment: e.target.value
      });
    },
    // 保存选择的用户
    setNoticeList(payload) {
      this.noticeList = payload;
    },

    // 修改后重新发起审批
    reapply() {

      if(this.isSCRM) {
        if (this.detail.extra && this.detail.extra.url) {
          window.open(this.detail.extra.url)
        } else {
          this.$message.error('没有跳转链接')
        }
        return;
      }
      const url = this.getUrl();
      if (url) {
        this.$router.push(this.getUrl());
      } else {
        this.$message.error("暂未开发，请联系系统管理员");
      }
    },
    getUrl() {
      const text = this.detail;
      console.log("text", text);
      if (text.processDefinitionKey === "con_register") {
        // 跳转到合同登记页面
        const contractId = text.businessKey
          ? text.businessKey.substring(3)
          : "";

        return `/produce/contractRegister/review/add?id=${contractId}`;
      } else if (text.processDefinitionKey === "con_amount_adjust") {
        // 跳转到合同调整页面
        const id = this.detail?.extra?.contractChangeAmountData?.id;
        return `/produce/contract-adjust/add?taskId=${text.taskId}&instanceId=${text.processInstanceId}&id=${id}`;
      } else if (text.processDefinitionKey === "con_kj_register") {
        // 跳转到框架类合同登记页面
        const contractId = text.businessKey
          ? text.businessKey.substring(3)
          : "";

        return `/produce/contractRegister/framework/add?id=${contractId}`;
      } else if (text.processDefinitionKey === "subcon_register") {
        // 跳转到分包登记页面
        return `/produce/contractRegister/subpackage/add?taskId=${text.taskId}&instanceId=${text.processInstanceId}`;
      } else if (text.processDefinitionKey === "con_configure") {
        // 跳转到合同编辑页面
        const contractId = text.businessKey
          ? text.businessKey.substring(3)
          : "";
        return `/produce/contract/edit?id=${contractId}`;
      } else if (text.processDefinitionKey === "prj_phase_progress") {
        let id = text.businessKey
          ? text.businessKey.substring(3).split(",")[0]
          : "";
        return `/produce/progress/detail?id=${id}`;
      } else if (text.processDefinitionKey === "fin_invoice") {
        return `/finance/invoice/apply?taskId=${text.taskId}&instanceId=${text.processInstanceId}`;
      } else if (text.processDefinitionKey === "prj_configure") {
        // 产值分配审批
        let id = text.businessKey
          ? text.businessKey.substring(3).split(",")[0]
          : "";

        const allocateCalcFormula =
          text?.extra?.staffConfigData?.contract?.allocateCalcFormula;
        const allocateCalcFactor =
          text?.extra?.staffConfigData?.contract?.allocateCalcFactor;

        console.log("allocateCalcFormula", allocateCalcFormula);
        console.log("allocateCalcFactor", allocateCalcFactor);

        if (allocateCalcFormula) {
          if (allocateCalcFormula === "2025") {
            return `/produce/progress/allocate2025?id=${id}&factor=${allocateCalcFactor}`;
          }else if (allocateCalcFormula === "2024") {
            return `/produce/progress/allocate2024?id=${id}&factor=${allocateCalcFactor}`;
          } else if (allocateCalcFormula === "2023") {
            return `/produce/progress/allocate2023?id=${id}&factor=${allocateCalcFactor}`;
          } else if (allocateCalcFormula === "2022") {
            return `/produce/progress/allocate2022?id=${id}&factor=${allocateCalcFactor}`;
          } else if (allocateCalcFormula === "2021") {
            return `/produce/progress/allocate2021?id=${id}`;
          }
        } else {
          return `/produce/progress/allocate?id=${id}`;
        }
      } else if (text.processDefinitionKey === "arc_borrow") {
        // 是否是档案出借审批
        const id = text.businessKey ? text.businessKey.substring(3) : "";
        return `/oa/archive/query/lend?id=${id}`;
      } else if (text.processDefinitionKey === "arc_transfer") {
        // 是否是档案移交审批
        const id = text.businessKey ? text.businessKey.substring(3) : "";
        return `/oa/archive/query/transfer?id=${id}`;
      } else if (text.processDefinitionKey === "ofc_inv_apply") {
        // 是否是酒水审批
        const id = text.businessKey ? text.businessKey.substring(3) : "";
        return `/oa/material/apply?id=${id}`;
      } else if (
        text.processDefinitionKey === "att_leave" ||
        text.processDefinitionKey === "att_on_business"
      ) {
        // 请假审批/出差或公出审批
        const { id, type } = text.extra || {};
        return `/human-resources/clock/add?type=${type}&id=${id}&control=resubmit`;
      } else if (text.processDefinitionKey === "att_check_apply") {
        // 补卡审批
        const { id } = text.extra || {};
        return `/human-resources/clock/replace?id=${id}`;
      } else if (text.processDefinitionKey === "ofc_quality_plan") {
        const id = text.businessKey ? text.businessKey.substring(3) : "";
        return `/technical-quality/security-check-management/security-check/detail?id=${id}`;
      } else if (text.processDefinitionKey === "ofc_quality_work") {
        const { parentId } = text.extra || {};
        return `/technical-quality/security-check-management/security-check/detail?id=${parentId}&active=1`;
      } else if (text.processDefinitionKey === "ofc_quality_rectify") {
        const { parentId } = text.extra || {};
        return `/technical-quality/security-check-management/security-check/detail?id=${parentId}&active=2`;
      } else if (text.processDefinitionKey === "safety_collaborate") {
        const id = text.businessKey ? text.businessKey.substring(3) : "";
        return `/collaborate?id=${id}`;
      } else {
        return "";
      }
    }
  },
  beforeDestroy() {
    this.setSignUser([]);
    this.setSignType("");
    this.setOnSet(false);
  }
};
</script>

<style lang="less" scoped>
.button {
  display: flex;
  align-items: center;
  color: #1890ff;
  border-color: #1890ff;

  .icon {
    height: 10px;
    margin-right: 2px;
  }
}

.menu-item {
  display: flex;
  align-items: center;

  .icon {
    height: 12px;
    margin-right: 6px;
  }
}

.control {
  position: absolute;
  top: 56px;
  right: 12px;
  z-index: 99;
}
</style>
